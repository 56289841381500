// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-camp-js": () => import("/opt/build/repo/src/pages/camp.js" /* webpackChunkName: "component---src-pages-camp-js" */),
  "component---src-pages-clips-js": () => import("/opt/build/repo/src/pages/clips.js" /* webpackChunkName: "component---src-pages-clips-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-materials-js": () => import("/opt/build/repo/src/pages/course_materials.js" /* webpackChunkName: "component---src-pages-course-materials-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("/opt/build/repo/src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

